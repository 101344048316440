const actions = {
  // generic actions
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  // search actions
  SEARCH: 'SEARCH',
  START_SEARCH: 'START_SEARCH',
  END_SEARCH: 'END_SEARCH',
  // filter actions
  TOGGLE_FILTER_PANEL: 'TOGGLE_FILTER_PANEL',
  // store listing actions
  TOGGLE_STORE_LIST_DIALOG: 'TOGGLE_STORE_LIST_DIALOG',
  // tag actions
  LOAD_TAGS_TRANSACTION: 'LOAD_TAGS_TRANSACTION',
  LOAD_TAGS: 'LOAD_TAGS',
  LOAD_TAGS_FAILED: 'LOAD_TAGS_FAILED',
  LOADING_TAGS: 'LOADING_TAGS',
  TAGS_LOADED: 'TAGS_LOADED',
  SAVE_TAG: 'SAVE_TAG',
  SHOW_SAVED_TAG_MESSAGE: 'SHOW_SAVED_TAG_MESSAGE',
  SAVED_TAG_MESSAGE: 'SAVED_TAG_MESSAGE',
  MOVE_TAG: 'MOVE_TAG',
  UPDATE_TAG: 'UPDATE_TAG',
  SHOW_MENU_EVENT_MESSAGE: 'SHOW_MENU_EVENT_MESSAGE',
  TAG_FOLDER_MESSAGE: 'TAG_FOLDER_MESSAGE',
  MENU_EVENT_MESSAGE: 'MENU_EVENT_MESSAGE',
  MENU_EVENT_TRIGGERING: 'MENU_EVENT_TRIGGERING',
  SET_EDIT_TAG_DATA: 'SET_EDIT_TAG_DATA',
  TAG_ADDED: 'TAG_ADDED',
  TAG_SAVING: 'TAG_SAVING',
  TAG_MOVING: 'TAG_MOVING',
  TAG_MOVED: 'TAG_MOVED',
  TAG_ADD_FAILED: 'TAG_ADD_FAILED',
  TOGGLE_TAG_DIALOG: 'TOGGLE_TAG_DIALOG',
  TOGGLE_EDIT_TAG_DIALOG: 'TOGGLE_EDIT_TAG_DIALOG',
  TAG_UPDATING: 'TAG_UPDATING',
  TAG_UPDATING_FAILED: 'TAG_UPDATING_FAILED',
  DELETE_TAG: 'DELETE_TAG',
  TAG_DELETING: 'TAG_DELETING',
  TAG_DELETE_FAILED: 'TAG_DELETE_FAILED',
  TAG_DELETED: 'TAG_DELETED',
  // folder actions
  LOAD_FOLDERS_TRANSACTION: 'LOAD_FOLDERS_TRANSACTION',
  LOAD_FOLDERS: 'LOAD_FOLDERS',
  LOAD_FOLDERS_FAILED: 'LOAD_FOLDERS_FAILED',
  LOADING_FOLDERS: 'LOADING_FOLDERS',
  FOLDERS_LOADED: 'FOLDERS_LOADED',
  SAVE_FOLDER: 'SAVE_FOLDER',
  MOVE_FOLDER: 'MOVE_FOLDER',
  UPDATE_FOLDER: 'UPDATE_FOLDER',
  SET_EDIT_FOLDER_DATA: 'SET_EDIT_FOLDER_DATA',
  FOLDER_ADDED: 'FOLDER_ADDED',
  FOLDER_SAVING: 'FOLDER_SAVING',
  FOLDER_UPDATING: 'FOLDER_UPDATING',
  FOLDER_UPDATING_FAILED: 'FOLDER_UPDATING_FAILED',
  FOLDER_ADD_FAILED: 'FOLDER_ADD_FAILED',
  TOGGLE_FOLDER_DIALOG: 'TOGGLE_FOLDER_DIALOG',
  TOGGLE_EDIT_FOLDER_DIALOG: 'TOGGLE_EDIT_FOLDER_DIALOG',
  DELETE_FOLDER: 'DELETE_FOLDER',
  FOLDER_DELETING: 'FOLDER_DELETING',
  FOLDER_DELETE_FAILED: 'FOLDER_DELETE_FAILED',
  FOLDER_DELETED: 'FOLDER_DELETED',
  DELETE_FOLDER_TRANSACTION: 'DELETE_FOLDER_TRANSACTION',
  FOLDER_TRANSACTION_DELETING: 'FOLDER_TRANSACTION_DELETING',
  FOLDER_TRANSACTION_DELETE_FAILED: 'FOLDER_TRANSACTION_DELETE_FAILED',
  FOLDER_TRANSACTION_DELETED: 'FOLDER_TRANSACTION_DELETED',
  FOLDER_MOVING: 'FOLDER_MOVING',
  FOLDER_MOVED: 'FOLDER_MOVED',
  TXN_TAG_REMOVED: 'TAG_REMOVED',
  REMOVING_TXN_TAG: 'REMOVING_TXN_TAG',
  TXN_TAG_REMOVE_FAILED: 'TXN_TAG_REMOVE_FAILED',
  REMOVE_TXN_TAG: 'REMOVE_TXN_TAG',
  // LOADING ES BY TYPE ID
  LOAD_ES_BY_TYPE_ID: 'LOAD_ES_BY_TYPE_ID',
  // LOADING ES BY STORE
  LOAD_ES_TRX_BY_STORE: 'LOAD_ES_TRX_BY_STORE',
  LOAD_MORE_ES_TRX: 'LOAD_MORE_ES_TRX',
  LOADING_ES_TRX: 'LOADING_ES_TRX',
  ES_TRX_VALUE: 'ES_TRX_VALUE',
  LOADING_ES_TRX_SUCCESS: 'LOADING_ES_TRX_SUCCESS',
  LOADING_ES_TRX_ERROR: 'LOADING_ES_TRX_ERROR',
  // load transaction events
  LOAD_ALL_TRANSACTION: 'LOAD_ALL_TRANSACTION',
  LOADING_TRANSACTIONS: 'LOADING_TRANSACTIONS',
  LOAD_MORE_TRANSACTIONS: 'LOAD_MORE_TRANSACTIONS',
  LOADING_MORE_TRANSACTIONS: 'LOADING_MORE_TRANSACTIONS',
  TRANSACTIONS_LOADED: 'TRANSACTIONS_LOADED',
  SEARCH_TRANSACTIONS_LOADED: 'SEARCH_TRANSACTIONS_LOADED',
  LOAD_TRANSACTIONS_FAILED: 'LOAD_TRANSACTIONS_FAILED',
  // PENDING TRANSACTION
  LOAD_PENDING_TRANSACTION: 'LOAD_PENDING_TRANSACTION',
  LOADING_PENDING_TRANSACTION: 'LOADING_PENDING_TRANSACTION',
  LOADED_PENDING_TRANSACTION: 'LOADED_PENDING_TRANSACTION',
  LOAD_PENDING_TRANSACTION_FAILED: 'LOAD_PENDING_TRANSACTION_FAILED',
  // REFUNDED TRANSACTION
  LOAD_REFUNDED_TRANSACTION: 'LOAD_REFUNDED_TRANSACTION',
  LOADING_REFUNDED_TRANSACTION: 'LOADING_REFUNDED_TRANSACTION',
  LOADED_REFUNDED_TRANSACTION: 'LOADED_REFUNDED_TRANSACTION',
  LOAD_REFUNDED_TRANSACTION_FAILED: 'LOAD_REFUNDED_TRANSACTION_FAILED',
  // ISSUE REFUND
  ISSUE_REFUND: 'ISSUE_REFUND',
  ISSUING_REFUND: 'ISSUING_REFUND',
  REFUND_ISSUED: 'REFUND_ISSUED',
  ISSUE_REFUND_FAILED: 'ISSUE_REFUND_FAILED',
  REFUND_MESSAGE: 'REFUND_MESSAGE',
  // PAID TRANSACTIONS
  LOAD_PAID_TRANSACTION: 'LOAD_PAID_TRANSACTION',
  LOADING_PAID_TRANSACTION: 'LOADING_PAID_TRANSACTION',
  LOADED_PAID_TRANSACTION: 'LOADED_PAID_TRANSACTION',
  LOAD_PAID_TRANSACTION_FAILED: 'LOAD_PAID_TRANSACTION_FAILED',
  // FAILED TRANSACTION
  LOAD_FAILED_TRANSACTION: 'LOAD_FAILED_TRANSACTION',
  LOADING_FAILED_TRANSACTION: 'LOADING_FAILED_TRANSACTION',
  LOADED_FAILED_TRANSACTION: 'LOADED_FAILED_TRANSACTION',
  LOAD_FAILED_TRANSACTION_FAILED: 'LOAD_FAILED_TRANSACTION_FAILED',
  SET_CURRENT_TRANSACTION: 'SET_CURRENT_TRANSACTION',
  //POSTING TRANSACTION REMARKS
  POST_REMARKS: 'POST_REMARKS',
  REMARKS_POSTING: 'REMARKS_POSTING',
  REMARKS_POSTED: 'REMARKS_POSTED',
  REMARKS_POST_FAILED: 'REMARKS_POST_FAILED',
  // menu clicking actions
  SET_TITLE: 'SET_TITLE',
  CLEAR_TRANSACTION: 'CLEAR_TRANSACTION',
  CLEAR_TRANSACTION_ONLY: 'CLEAR_TRANSACTION_ONLY',
  // MQTT INCOMING TRANSACTION
  MQTT_INCOMMING_TRX: 'MQTT_INCOMMING_TRX',
  MQTT_INCOMMING_LOADING: 'MQTT_INCOMMING_LOADING',
  MQTT_INCOMMING_FAILED: 'MQTT_INCOMMING_FAILED',
  // SETVIEW
  SET_VIEW: 'SET_VIEW',
  MQTT_TRX: 'MQTT_TRX',
  // STORE INVENTORY
  LOAD_STORE_INVENTORY: 'LOAD_STORE_INVENTORY',
  LOADING_STORE_INVENTORY: 'LOADING_STORE_INVENTORY',
  STORE_INVENTORY_LOADED: 'STORE_INVENTORY_LOADED',
  LOAD_STORE_INVENTORY_FAILED: 'LOAD_STORE_INVENTORY_FAILED',
  RESET_STORE_INVENTORY: 'RESET_STORE_INVENTORY',
  COUNT_STORE_INVENTORY: 'COUNT_STORE_INVENTORY',
  COUNTING_STORE_INVENTORY: 'COUNTING_STORE_INVENTORY',
  COUNT_STORE_INVENTORY_RESULT: 'COUNT_STORE_INVENTORY_RESULT',
  // STORE PCHANNELS
  LOAD_ALLOWED_PCHANNEL: 'LOAD_ALLOWED_PCHANNEL',
  LOADING_ALLOWED_PCHANNEL: 'LOADING_ALLOWED_PCHANNEL',
  ALLOWED_PCHANNEL_LOADED: 'ALLOWED_PCHANNEL_LOADED',
  LOAD_ALLOWED_PCHANNEL_FAILED: 'LOAD_ALLOWED_PCHANNEL_FAILED',
  RESET_ALLOWED_PCHANNEL: 'RESET_ALLOWED_PCHANNEL',
  // ADDRESS
  // USED FOR PRIMARY LOADING OF PROVINCE
  LOAD_PROVINCE: 'LOAD_PROVINCE',
  LOADING_PROVINCE: 'LOADING_PROVINCE',
  PROVINCE_LOADED: 'PROVINCE_LOADED',
  LOAD_PROVINCE_FAILED: 'LOAD_PROVINCE_FAILED',
  RESET_PROVINCE: 'RESET_PROVINCE',
  // USED FOR SECONDARY SELECTING OF PROVINCE
  LOAD_PROVINCE_S: 'LOAD_PROVINCE_S',
  LOADING_PROVINCE_S: 'LOADING_PROVINCE_S',
  PROVINCE_LOADED_S: 'PROVINCE_LOADED_S',
  LOAD_PROVINCE_FAILED_S: 'LOAD_PROVINCE_FAILED_S',
  RESET_PROVINCE_S: 'RESET_PROVINCE_S',
  // USED FOR PRIMARY LOADING OF CITY
  LOAD_CITY: 'LOAD_CITY',
  LOADING_CITY: 'LOADING_CITY',
  CITY_LOADED: 'CITY_LOADED',
  LOAD_CITY_FAILED: 'LOAD_CITY_FAILED',
  RESET_CITY: 'RESET_CITY',
  // USED FOR SECONDARY SELECTING OF CITY
  LOAD_CITY_S: 'LOAD_CITY_S',
  LOADING_CITY_S: 'LOADING_CITY_S',
  CITY_LOADED_S: 'CITY_LOADED_S',
  LOAD_CITY_FAILED_S: 'LOAD_CITY_FAILED_S',
  RESET_CITY_S: 'RESET_CITY_S',
  // USED FOR PRIMARY LOADING OF BRGY 
  LOAD_BRGY: 'LOAD_BRGY',
  LOADING_BRGY: 'LOADING_BRGY',
  BRGY_LOADED: 'BRGY_LOADED',
  LOAD_BRGY_FAILED: 'LOAD_BRGY_FAILED',
  RESET_BRGY: 'RESET_BRGY',
  // USED FOR SECONDARY SELECTING OF BRGY
  LOAD_BRGY_S: 'LOAD_BRGY_S',
  LOADING_BRGY_S: 'LOADING_BRGY_S',
  BRGY_LOADED_S: 'BRGY_LOADED_S',
  LOAD_BRGY_FAILED_S: 'LOAD_BRGY_FAILED_S',
  RESET_BRGY_S: 'RESET_BRGY_S',
  // 
  LOAD_TEMP_TRANSACTION: 'LOAD_TEMP_TRANSACTION',
  LOADING_TEMP_TRANSACTION: 'LOADING_TEMP_TRANSACTION',
  TEMP_TRANSACTION_LOADED: 'TEMP_TRANSACTION_LOADED',
  LOAD_TEMP_TRANSACTION_FAILED: 'LOAD_TEMP_TRANSACTION_FAILED',
  RESET_TEMP_TRANSACTION: 'RESET_TEMP_TRANSACTION',

  SUBMIT_TEMP_TRX: 'SUBMIT_TEMP_TRX',
  SUBMITTING_TEMP_TRX: 'SUBMITTING_TEMP_TRX',
  TEMP_TRX_SUBMITTED: 'TEMP_TRX_SUBMITTED',
  // URL: 'URL',
  SUBMIT_TEMP_TRX_FAILED: 'SUBMIT_TEMP_TRX_FAILED',
  SUBMIT_TEMP_TRX_RESET: 'SUBMIT_TEMP_TRX_RESET',


  SUBMIT_TRX_REDIRECT_CUSTOMER: 'SUBMIT_TRX_REDIRECT_CUSTOMER',
  SUBMITTING_REDIRECT_CUSTOMER_TRX: 'SUBMITTING_REDIRECT_CUSTOMER_TRX',
  REDIRECT_CUSTOMER_TRX_SUBMITTED: 'REDIRECT_CUSTOMER_TRX_SUBMITTED',
  REDIRECT_CUSTOMER_PCHANNEL_DATA: 'REDIRECT_CUSTOMER_PCHANNEL_DATA',
  SUBMIT_TRX_REDIRECT_CUSTOMER_ERROR: 'SUBMIT_TRX_REDIRECT_CUSTOMER_ERROR',
  RESET_TRX_REDIRECT_CUSTOMER_ERROR: 'RESET_TRX_REDIRECT_CUSTOMER_ERROR',
  UPDATED_TRX_TEMP: 'UPDATED_TRX_TEMP',

  // SUBMIT PRE REGISTERED APPLICATION
  SUBMIT_APPLICATION: 'SUBMIT_APPLICATION',
  SUBMITTING_APPLICATION: 'SUBMITTING_APPLICATION',
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  SUBMITTING_APPLICATION_FAILED: 'SUBMITTING_APPLICATION_FAILED',
  SUBMITTING_APPLICATION_ERROR: 'SUBMITTING_APPLICATION_ERROR',
  RESET_APPLICATION: 'RESET_APPLICATION',
  // SET BIZ MODULE
  SET_BIZ_MODULE: 'SET_BIZ_MODULE',

  // GET LIMIT
  GET_THRESHOLD: 'GET_THRESHOLD',
  GETTING_THRESHOLD: 'GETTING_THRESHOLD',
  GETTING_THRESHOLD_FAILED: 'GETTING_THRESHOLD_FAILED',
  GOT_THRESHOLD: 'GOT_THRESHOLD',

  //TEMP_ERROR_MESSAGE
  TEMP_ERROR_MESSAGE: 'TEMP_ERROR_MESSAGE',

  // SUBMIT TEMP TRANSACTION
  SUBMIT_TEMP_TRANSACTION: 'SUBMIT_TEMP_TRANSACTION',
  SUBMITTING_TEMP_TRANSACTION: 'SUBMITTING_TEMP_TRANSACTION',
  SUBMITTED_TEMP_TRANSACTION: 'SUBMITTED_TEMP_TRANSACTION',
  SUBMIT_TEMP_TRANSACTION_FAILED: 'SUBMIT_TEMP_TRANSACTION_FAILED',
  RESET_TEMP_TRANSACTION_MESSAGE: 'RESET_TEMP_TRANSACTION_MESSAGE',

  //GET TRX STATUS
  FETCH_TRX_STATUS: 'FETCH_TRX_STATUS',
  FETCHING_TRX_STATUS: 'FETCHING_TRX_STATUS',
  FETCHED_TRX_STATUS: 'FETCHED_TRX_STATUS',
  FETCH_TRX_STATUS_FAILED: 'FETCH_TRX_STATUS_FAILED',

  //SEARCH AUDITLOGS
  SEARCH_AUDITLOGS: 'SEARCH_AUDITLOGS',
  SEARCHING_AUDITLOGS: 'SEARCHING_AUDITLOGS',
  SEARCHED_AUDITLOGS: 'SEARCHED_AUDITLOGS',
  SEARCH_AUDITLOGS_FAILED: 'SEARCH_AUDITLOGS_FAILED',
  RESET_AUDITLOGS: 'RESET_AUDITLOGS',

  //SEARCH MERCHANT AUDITLOGS
  SEARCH_MERCHANT_AUDITLOGS: 'SEARCH_MERCHANT_AUDITLOGS',
  SEARCHING_MERCHANT_AUDITLOGS: 'SEARCHING_MERCHANT_AUDITLOGS',
  SEARCHED_MERCHANT_AUDITLOGS: 'SEARCHED_MERCHANT_AUDITLOGS',
  SEARCH_MERCHANT_AUDITLOGS_FAILED: 'SEARCH_MERCHANT_AUDITLOGS_FAILED',
  RESET_MERCHANT_AUDITLOGS: 'RESET_MERCHANT_AUDITLOGS',

  //LOAD MORE MERCHANT LOGS
  LOAD_MORE_MERCHANT_LOGS: 'LOAD_MORE_MERCHANT_LOGS',
  LOADING_MORE_MERCHANT_LOGS: 'LOADING_MORE_MERCHANT_LOGS',
  LOADED_MORE_MERCHANT_LOGS: 'LOADED_MORE_MERCHANT_LOGS',
  LOAD_MORE_MERCHANT_LOGS_FAILED: 'LOAD_MORE_MERCHANT_LOGS_FAILED',

  //LOAD MORE LOGS
  LOAD_MORE_LOGS: 'LOAD_MORE_LOGS',
  LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
  LOADED_MORE_LOGS: 'LOADED_MORE_LOGS',
  LOAD_MORE_LOGS_FAILED: 'LOAD_MORE_LOGS_FAILED',
  // DOWNLOAD TRANSACTION PDF
  DOWNLOAD_TRANSACTION_PDF: 'DOWNLOAD_TRANSACTION_PDF',
  DOWNLOADING_TRANSACTION_PDF: 'DOWNLOADING_TRANSACTION_PDF',
  DOWNLOADED_TRANSACTION_PDF: 'DOWNLOADED_TRANSACTION_PDF',
  DOWNLOAD_TRANSACTION_PDF_FAILED: 'DOWNLOAD_TRANSACTION_PDF_FAILED',
  RESET_DOWNLOAD_TRANSACTION_PDF: 'RESET_DOWNLOAD_TRANSACTION_PDF',
  // FETCH TRX FEES
  FETCH_PAYMENT_FEES: 'FETCH_PAYMENT_FEES',
  FETCHING_PAYMENT_FEES: 'FETCHING_PAYMENT_FEES',
  FETCH_PAYMENT_FEES_FAILED: 'FETCH_PAYMENT_FEES_FAILED',
  FETCH_PAYMENT_FEES_SUCCESS: 'FETCH_PAYMENT_FEES_SUCCESS',

  //FETCH TRANSACTION FEES
  FETCH_TRANSACTION_FEE: 'FETCH_TRANSACTION_FEE',
  FETCHING_TRANSCTION_FEE: 'FETCHING_TRANSCTION_FEE',
  FETCHED_TRANSACTION_FEE: 'FETCHED_TRANSACTION_FEE',
  FETCH_TRANSACTION_FEE_FAILED: 'FETCH_TRANSACTION_FEE_FAILED',

  // SEND SMS LINK
  SEND_SMS_LINK: 'SEND_SMS_LINK',
  SENDING_SMS_LINK: 'SENDING_SMS_LINK',
  SENT_SMS_LINK: 'SENT_SMS_LINK',
  SENDING_SMS_LINK_FAILED: 'SENDING_SMS_LINK_FAILED',
  RESET_SEND_SMS_LINK: 'RESET_SEND_SMS_LINK',
  
  // FETCH BOC TRANSACTION DATA
  FETCH_BOC_TRANSACTION_DATA: 'FETCH_BOC_TRANSACTION_DATA',
  FETCHING_BOC_TRANSACTION_DATA: 'FETCHING_BOC_TRANSACTION_DATA',
  FETCHED_BOC_TRANSACTION_DATA: 'FETCHED_BOC_TRANSACTION_DATA',
  FETCH_BOC_TRANSACTION_DATA_FAILED: 'FETCH_BOC_TRANSACTION_DATA_FAILED',

  // FETCH BOC TRANSACTION DATA
  FETCH_DETAILED_TRANSACTION_DATA: 'FETCH_DETAILED_TRANSACTION_DATA',
  FETCHING_DETAILED_TRANSACTION_DATA: 'FETCHING_DETAILED_TRANSACTION_DATA',
  FETCHED_DETAILED_TRANSACTION_DATA: 'FETCHED_DETAILED_TRANSACTION_DATA',
  FETCH_DETAILED_TRANSACTION_DATA_FAILED: 'FETCH_DETAILED_TRANSACTION_DATA_FAILED',

  // CANCEL TRANSACTION
  CANCEL_TRANSACTION: 'CANCEL_TRANSACTION',
  CANCELLING_TRANSACTION: 'CANCELLING_TRANSACTION',
  CANCELED_TRANSACTION: 'CANCELED_TRANSACTION',
  CANCEL_TRANSACTION_FAILED: 'CANCEL_TRANSACTION_FAILED',
  CANCEL_TRANSACTION_RESET: 'CANCEL_TRANSACTION_RESET',

  // VALIDATE TIN AND EMAIL
  VALIDATE_APPLICATION: 'VALIDATE_APPLICATION',
  VALIDATING_APPLICATION: 'VALIDATING_APPLICATION',
  VALIDATED_APPLICATION: 'VALIDATED_APPLICATION',
  VALIDATE_APPLICATION_FAILED: 'VALIDATE_APPLICATION_FAILED',
  RESET_VALIDATION: 'RESET_VALIDATION',

  // FETCH TRANSACTION FOR REDIRECT PAYMENT CHANNEL
  FETCH_TRANSACTION_PAYMENT: 'FETCH_TRANSACTION_PAYMENT',
  FETCHING_TRANSACTION_PAYMENT: 'FETCHING_TRANSACTION_PAYMENT',
  FETCHED_TRANSACTION_PAYMENT: 'FETCHED_TRANSACTION_PAYMENT',
  FETCH_TRANSACTION_PAYMENT_FAILED: 'FETCH_TRANSACTION_PAYMENT_FAILED',
  
  // FETCH SHIPPING SETUP
  FETCH_SHIPPING_SETUP: 'FETCH_SHIPPING_SETUP',
  FETCHING_SHIPPING_SETUP: 'FETCHING_SHIPPING_SETUP',
  FETCHED_SHIPPING_SETUP: 'FETCHED_SHIPPING_SETUP',
  FETCH_SHIPPING_SETUP_FAILED: 'FETCH_SHIPPING_SETUP_FAILED',

  // FETCH REFUND VALIDATOR BY PCHANNEL
  FETCH_REFUND_VALIDATOR: 'FETCH_REFUND_VALIDATOR',
  FETCHING_REFUND_VALIDATOR: 'FETCHING_REFUND_VALIDATOR',
  FETCHED_REFUND_VALIDATOR: 'FETCHED_REFUND_VALIDATOR',
  FETCH_REFUND_VALIDATOR_FAILED: 'FETCH_REFUND_VALIDATOR_FAILED',

  // FETCH CUSTOM FEE CALCULATOR
  FETCH_CUSTOM_FEE_CALCULATOR: 'FETCH_CUSTOM_FEE_CALCULATOR',
  FETCHING_CUSTOM_FEE_CALCULATOR: 'FETCHING_CUSTOM_FEE_CALCULATOR',
  FETCHED_CUSTOM_FEE_CALCULATOR: 'FETCHED_CUSTOM_FEE_CALCULATOR',
  FETCH_CUSTOM_FEE_CALCULATOR_FAILED: 'FETCH_CUSTOM_FEE_CALCULATOR_FAILED',

  //GET ITEM BODY DETAILS
  FETCH_ITEM_BODY: 'FETCH_ITEM_BODY',
  FETCHING_ITEM_BODY: 'FETCHING_ITEM_BODY',
  FETCHED_ITEM_BODY: 'FETCHED_ITEM_BODY',
  FETCH_ITEM_BODY_FAILED: 'FETCH_ITEM_BODY_FAILED',

  //GET ITEM BODY DETAILS
  FETCH_BOOKING_REQUEST: 'FETCH_BOOKING_REQUEST',
  FETCHING_BOOKING_REQUEST: 'FETCHING_BOOKING_REQUEST',
  FETCHED_BOOKING_REQUEST: 'FETCHED_BOOKING_REQUEST',
  FETCH_BOOKING_REQUEST_FAILED: 'FETCH_BOOKING_REQUEST_FAILED',
  RESET_BOOKING_REQUEST: 'RESET_BOOKING_REQUEST',
  RESET_BOOKING_MESSAGE: 'RESET_BOOKING_MESSAGE',

  COURIER_FEE: 'COURIER_FEE',

  FETCH_MERCHANT_STORE_SETTINGS_TRANX: 'FETCH_MERCHANT_STORE_SETTINGS_TRANX',
  FETCHING_MERCHANT_STORE_SETTINGS_TRANX: 'FETCHING_MERCHANT_STORE_SETTINGS_TRANX',
  FETCHED_MERCHANT_STORE_SETTINGS_TRANX: 'FETCHED_MERCHANT_STORE_SETTINGS_TRANX',
  FETCH_MERCHANT_STORE_SETTINGS_FAILED_TRANX: 'FETCH_MERCHANT_STORE_SETTINGS_FAILED_TRANX',

  FETCH_STORE_SETTINGS: 'FETCH_STORE_SETTINGS',
  FETCHING_STORE_SETTINGS: 'FETCHING_STORE_SETTINGS',
  FETCHED_STORE_SETTINGS: 'FETCHED_STORE_SETTINGS',
  FETCH_STORE_SETTINGS_FAILED: 'FETCH_STORE_SETTINGS_FAILED',

  //GET COURIER ORDER
  FETCH_COURIER_ORDER: 'FETCH_COURIER_ORDER',
  FETCHING_COURIER_ORDER: 'FETCHING_COURIER_ORDER',
  FETCHED_COURIER_ORDER: 'FETCHED_COURIER_ORDER',
  FETCH_COURIER_ORDER_FAILED: 'FETCH_COURIER_ORDER_FAILED',

  //GET BOOKING STATUS
  FETCH_BOOKING_STATUS: 'FETCH_BOOKING_STATUS',
  FETCHING_BOOKING_STATUS: 'FETCHING_BOOKING_STATUS',
  FETCHED_BOOKING_STATUS: 'FETCHED_BOOKING_STATUS',
  FETCH_BOOKING_STATUS_FAILED: 'FETCH_BOOKING_STATUS_FAILED',

  //GET TIMELINE
  FETCH_SHIPPING_TIMELINE: 'FETCH_SHIPPING_TIMELINE',
  FETCHING_SHIPPING_TIMELINE: 'FETCHING_SHIPPING_TIMELINE',
  FETCHED_SHIPPING_TIMELINE: 'FETCHED_SHIPPING_TIMELINE',
  FETCHING_SHIPPING_TIMELINE_FAILED: 'FETCHING_SHIPPING_TIMELINE_FAILED',

  // UPDATE TIMELINE
  FETCHED_TIMELINE: 'FETCHED_TIMELINE',
  FETCH_SHIPPING_MQTT_RESULT: 'FETCH_SHIPPING_MQTT_RESULT',
  RELOAD_SHIPPING_TIMELINE: 'RELOAD_SHIPPING_TIMELINE',
  
  SHIPPING_TIMELINE_MQTT: 'SHIPPING_TIMELINE_MQTT',
  RESET_SHIPPING_TIMELINE_MQTT: 'RESET_SHIPPING_TIMELINE_MQTT',
  SHIPPING_TIMELINE_MQTT_ERROR_MESSAGE: 'SHIPPING_TIMELINE_MQTT_ERROR_MESSAGE',

  // FETCH CUSTOMER DATA IN PHONE BOOK BY IP ADDRESS
  FETCH_CUSTOMER_DATA_BY_IP_ADDRESS: 'FETCH_CUSTOMER_DATA_BY_IP_ADDRESS',
  FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_SUCCESS: 'FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_SUCCESS',
  FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_LOADING: 'FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_LOADING',
  FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_FAILED: 'FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_FAILED',

  // DELETE CUSTOMER DETAILS IN PHONE BOOK
  DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK: "DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK",
  DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_SUCCESS: "DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_SUCCESS",
  DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_LOADING: "DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_LOADING",
  DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_FAILED: "DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_FAILED",
  DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_RESET: "DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_RESET",

  // CHECK IF BIR RULING COMPLIED
  CHECK_IF_BIR_RULING_COMPLIED: 'CHECK_IF_BIR_RULING_COMPLIED',
  CHECK_IF_BIR_RULING_COMPLIED_SUCCESS: 'CHECK_IF_BIR_RULING_COMPLIED_SUCCESS',
  CHECK_IF_BIR_RULING_COMPLIED_LOADING: 'CHECK_IF_BIR_RULING_COMPLIED_LOADING',
  CHECK_IF_BIR_RULING_COMPLIED_FAILED: 'CHECK_IF_BIR_RULING_COMPLIED_FAILED',
  CHECK_IF_BIR_RULING_COMPLIED_RESET: 'CHECK_IF_BIR_RULING_COMPLIED_RESET',

  // FETCH SUPPLIER INFOMATION
  FETCH_SUPPLIER_INFO: 'FETCH_SUPPLIER_INFO',
  FETCH_SUPPLIER_INFO_SUCCESS: 'FETCH_SUPPLIER_INFO_SUCCESS',
  FETCH_SUPPLIER_INFO_LOADING: 'FETCH_SUPPLIER_INFO_LOADING',
  FETCH_SUPPLIER_INFO_FAILED: 'FETCH_SUPPLIER_INFO_FAILED',
  FETCH_SUPPLIER_INFO_RESET: 'FETCH_SUPPLIER_INFO_RESET',

  // ADD NEW SUPPLIER INFORMATION
  SAVE_SUPPLIER_INFO: 'SAVE_SUPPLIER_INFO',
  SAVE_SUPPLIER_INFO_SUCCESS: 'SAVE_SUPPLIER_INFO_SUCCESS',
  SAVE_SUPPLIER_INFO_LOADING: 'SAVE_SUPPLIER_INFO_LOADING',
  SAVE_SUPPLIER_INFO_FAILED: 'SAVE_SUPPLIER_INFO_FAILED',
  SAVE_SUPPLIER_INFO_RESET: 'SAVE_SUPPLIER_INFO_RESET',

  // UPDATE SUPPLIER INFORMATION
  UPDATE_SUPPLIER_INFO: 'UPDATE_SUPPLIER_INFO',
  UPDATE_SUPPLIER_INFO_SUCCESS: 'UPDATE_SUPPLIER_INFO_SUCCESS',
  UPDATE_SUPPLIER_INFO_LOADING: 'UPDATE_SUPPLIER_INFO_LOADING',
  UPDATE_SUPPLIER_INFO_FAILED: 'UPDATE_SUPPLIER_INFO_FAILED',
  UPDATE_SUPPLIER_INFO_RESET: 'UPDATE_SUPPLIER_INFO_RESET',

  // ADD NEW SUPPLIER FORM VALUES
  ADD_SUPPLIER_FORM_VALUES: 'ADD_SUPPLIER_FORM_VALUES',

  // SET SUPPLIERS FILTERED ARRAY
  SET_FILTERED_SUPPLIERS_ARRAY: 'SET_FILTERED_SUPPLIERS_ARRAY',
  
  RESET_CITY_BRGY: 'RESET_CITY_BRGY',

  //CWT SETTINGS REDUX
  FETCH_CWT_SETTINGS: 'FETCH_CWT_SETTINGS',
  FETCH_CWT_SETTINGS_LOADING: 'FETCH_CWT_SETTINGS_LOADING',
  FETCH_CWT_SETTINGS_SUCCESS: 'FETCH_CWT_SETTINGS_SUCCESS',
  FETCH_CWT_SETTINGS_FAILED: 'FETCH_CWT_SETTINGS_FAILED',
  FETCH_CWT_SETTINGS_RESET: 'FETCH_CWT_SETTINGS_RESET',

  UPDATE_CWT_SETTINGS: 'UPDATE_CWT_SETTINGS',
  UPDATE_CWT_SETTINGS_LOADING: 'UPDATE_CWT_SETTINGS_LOADING',
  UPDATE_CWT_SETTINGS_SUCCESS: 'UPDATE_CWT_SETTINGS_SUCCESS',
  UPDATE_CWT_SETTINGS_FAILED: 'UPDATE_CWT_SETTINGS_FAILED',
  UPDATE_CWT_SETTINGS_RESET: 'UPDATE_CWT_SETTINGS_RESET',

  // CREATE DISBURSEMENTS STEPS STATE
  CREATE_DISBURSEMENTS_STEPS_STATE: 'CREATE_DISBURSEMENTS_STEPS_STATE',

  // FETCH DISBURSEMENT LOGS
  FETCH_DISBURSEMENT_LOGS: 'FETCH_DISBURSEMENT_LOGS',
  DISBURSEMENT_LOGS: 'DISBURSEMENT_LOGS',
  DISBURSEMENT_LOGS_LOADING: 'DISBURSEMENT_LOGS_LOADING',
  DISBURSEMENT_LOGS_FAILED: 'DISBURSEMENT_LOGS_FAILED',
  RESET_DISBURSEMENT_LOGS: 'RESET_DISBURSEMENT_LOGS',

  // FETCH COSMOS OTP SETTINGS
  FETCH_COSMOS_OTP_SETTINGS: 'FETCH_COSMOS_OTP_SETTINGS',
  FETCH_COSMOS_OTP_SETTINGS_LOADING: 'FETCH_COSMOS_OTP_SETTINGS_LOADING',
  FETCH_COSMOS_OTP_SETTINGS_SUCCESS: 'FETCH_COSMOS_OTP_SETTINGS_SUCCESS',
  FETCH_COSMOS_OTP_SETTINGS_FAILED: 'FETCH_COSMOS_OTP_SETTINGS_FAILED',
  FETCH_COSMOS_OTP_SETTINGS_RESET: 'FETCH_COSMOS_OTP_SETTINGS_RESET',

  // REQUEST OTP FOR CREATING DISBURSEMENTS
  REQUEST_OTP_DISBURSEMENTS: 'REQUEST_OTP_DISBURSEMENTS',
  REQUEST_OTP_DISBURSEMENTS_SUCCESS: 'REQUEST_OTP_DISBURSEMENTS_SUCCESS',
  REQUEST_OTP_DISBURSEMENTS_LOADING: 'REQUEST_OTP_DISBURSEMENTS_LOADING',
  REQUEST_OTP_DISBURSEMENTS_FAILED: 'REQUEST_OTP_DISBURSEMENTS_FAILED',
  REQUEST_OTP_DISBURSEMENTS_RESET: 'REQUEST_OTP_DISBURSEMENTS_RESET',

  // VALIDATE OTP FOR CREATING DISBURSEMENTS
  VALIDATE_OTP_DISBURSEMENTS: 'VALIDATE_OTP_DISBURSEMENTS',
  VALIDATE_OTP_DISBURSEMENTS_SUCCESS: 'VALIDATE_OTP_DISBURSEMENTS_SUCCESS',
  VALIDATE_OTP_DISBURSEMENTS_LOADING: 'VALIDATE_OTP_DISBURSEMENTS_LOADING',
  VALIDATE_OTP_DISBURSEMENTS_FAILED: 'VALIDATE_OTP_DISBURSEMENTS_FAILED',
  VALIDATE_OTP_DISBURSEMENTS_RESET: 'VALIDATE_OTP_DISBURSEMENTS_RESET',

  // CREATE SUPPLIER DISBURSEMENTS
  CREATE_SUPPLIER_DISBURSEMENT: 'CREATE_SUPPLIER_DISBURSEMENT',
  CREATE_SUPPLIER_DISBURSEMENT_SUCCESS: 'CREATE_SUPPLIER_DISBURSEMENT_SUCCESS',
  CREATE_SUPPLIER_DISBURSEMENT_LOADING: 'CREATE_SUPPLIER_DISBURSEMENT_LOADING',
  CREATE_SUPPLIER_DISBURSEMENT_FAILED: 'CREATE_SUPPLIER_DISBURSEMENT_FAILED',
  CREATE_SUPPLIER_DISBURSEMENT_RESET: 'CREATE_SUPPLIER_DISBURSEMENT_RESET',

  // FETCH SUPPLIER DISBURSEMENTS
  FETCH_SUPPLIER_DISBURSEMENTS: 'FETCH_SUPPLIER_DISBURSEMENTS',
  FETCH_SUPPLIER_DISBURSEMENTS_SUCCESS: 'FETCH_SUPPLIER_DISBURSEMENTS_SUCCESS',
  FETCH_SUPPLIER_DISBURSEMENTS_LOADING: 'FETCH_SUPPLIER_DISBURSEMENTS_LOADING',
  FETCH_SUPPLIER_DISBURSEMENTS_FAILED: 'FETCH_SUPPLIER_DISBURSEMENTS_FAILED',
  FETCH_SUPPLIER_DISBURSEMENTS_RESET: 'FETCH_SUPPLIER_DISBURSEMENTS_RESET',

  // SUPPLIER DISBURSEMENTS
  SET_SUPPLIER_DISBURSEMENTS: 'SET_SUPPLIER_DISBURSEMENTS',

  fetchRefundValidator: (payload) => ({
    type: actions.FETCH_REFUND_VALIDATOR,
    payload: payload,
  }),
  loadFolders: (payload) => ({
    type: actions.LOAD_FOLDERS,
    payload: payload,
  }),
  setCurrentTransaction: (payload) => ({
    type: actions.SET_CURRENT_TRANSACTION,
    payload: payload,
  }),
  setEditFolderData: (payload) => ({
    type: actions.SET_EDIT_FOLDER_DATA,
    payload: payload,
  }),
  setEditTagData: (payload) => ({
    type: actions.SET_EDIT_TAG_DATA,
    payload: payload,
  }),
  toggleFilterPanel: () => ({
    type: actions.TOGGLE_FILTER_PANEL,
  }),
  toggleTagDialog: () => ({
    type: actions.TOGGLE_TAG_DIALOG,
  }),
  toggleFolderDialog: () => ({
    type: actions.TOGGLE_FOLDER_DIALOG,
  }),
  toggleEditFolderDialog: (payload) => ({
    type: actions.TOGGLE_EDIT_FOLDER_DIALOG,
    payload: payload,
  }),
  toggleEditTagDialog: (payload) => ({
    type: actions.TOGGLE_EDIT_TAG_DIALOG,
    payload: payload,
  }),
  toggleStoreListDialog: () => ({
    type: actions.TOGGLE_STORE_LIST_DIALOG,
  }),
  loadTags: (payload) => ({
    type: actions.LOAD_TAGS_TRANSACTION,
    payload: payload,
  }),
  fetchTags: () => ({
    type: actions.LOAD_TAGS,
  }),
  saveTag: (payload) => ({
    type: actions.SAVE_TAG,
    payload: payload,
  }),
  showSaveTagMessage: (payload) => ({
    type: actions.SHOW_SAVED_TAG_MESSAGE,
    payload: payload,
  }),
  saveTagMessage: (payload) => ({
    type: actions.SAVED_TAG_MESSAGE,
    payload: payload,
  }),
  showMenuEventMessage: (payload) => ({
    type: actions.SHOW_MENU_EVENT_MESSAGE,
    payload: payload,
  }),
  menuEventMessage: (payload) => ({
    type: actions.MENU_EVENT_MESSAGE,
    payload: payload,
  }),
  menuEventTriggering: (payload) => ({
    type: actions.MENU_EVENT_TRIGGERING,
    payload: payload,
  }),
  moveTag: (payload) => ({
    type: actions.MOVE_TAG,
    payload: payload,
  }),
  updateTag: (payload) => ({
    type: actions.UPDATE_TAG,
    payload: payload,
  }),
  deleteTag: (payload) => ({
    type: actions.DELETE_TAG,
    payload: payload,
  }),
  moveFolder: (payload) => ({
    type: actions.MOVE_FOLDER,
    payload: payload,
  }),
  saveFolder: (payload) => ({
    type: actions.SAVE_FOLDER,
    payload: payload,
  }),
  updateFolder: (payload) => ({
    type: actions.UPDATE_FOLDER,
    payload: payload,
  }),
  fetchFolders: () => ({
    type: actions.LOAD_FOLDERS,
  }),
  deleteFolder: (payload) => ({
    type: actions.DELETE_FOLDER,
    payload: payload,
  }),
  search: (payload) => ({
    type: actions.SEARCH,
    payload: payload,
  }),
  clearMessage: () => ({
    type: actions.CLEAR_MESSAGE,
  }),
  setTitle: (payload) => ({
    type: actions.SET_TITLE,
    payload: payload,
  }),
  fetchEsTrxByStore: (payload) => ({
    type: actions.LOAD_ES_TRX_BY_STORE,
    payload: payload,
  }),
  fetchMoreEsTrx: (payload) => ({
    type: actions.LOAD_MORE_ES_TRX,
    payload: payload,
  }),
  fetchEsTrxByTypeId: (payload) => ({
    type: actions.LOAD_ES_BY_TYPE_ID,
    payload: payload,
  }),
  loadTransactions: (payload, view) => ({
    type: actions.LOAD_ALL_TRANSACTION,
    payload: payload,
    view: view
  }),
  loadMoreTransactions: (payload) => ({
    type: actions.LOAD_MORE_TRANSACTIONS,
    payload: payload,
  }),
  resetTransaction: () => ({
    type: actions.CLEAR_TRANSACTION,
  }),
  resetTransactionOnly: () => ({
    type: actions.CLEAR_TRANSACTION_ONLY,
  }),
  postRemarks: (payload) => ({
    type: actions.POST_REMARKS,
    payload: payload,
  }),
  issueRefund: (payload) => ({
    type: actions.ISSUE_REFUND,
    payload: payload,
  }),
  cancelTransaction: (payload) => ({
    type: actions.CANCEL_TRANSACTION,
    payload: payload,
  }),
  cancelTransactionReset: () => ({
    type: actions.CANCEL_TRANSACTION_RESET,
  }),
  refundMessageReset: () => ({
    type: actions.REFUND_MESSAGE,
    payload: null,
    refundCode: null,
  }),
  newTransaction: (payload) => ({
    type: actions.MQTT_INCOMMING_TRX,
    payload,
  }),
  setView: (payload) => ({
    type: actions.SET_VIEW,
    payload,
  }),
  messageArrived: (payload) => ({
    type: actions.MQTT_TRX,
    payload: payload,
  }),
  loadStoreAssets: (payload) => ({
    type: actions.LOAD_STORE_INVENTORY,
    payload: payload,
  }),
  loadInventory: (payload) => ({
    type: actions.LOAD_STORE_INVENTORY,
    payload: payload,
  }),
  resetInventory: () => ({
    type: actions.RESET_STORE_INVENTORY,
  }),
  deleteFolderTxn: (payload, folderId) => ({
    type: actions.DELETE_FOLDER_TRANSACTION,
    payload: payload,
    folderId,
  }),
  deleteTransactionMessageReset: () => ({
    type: actions.FOLDER_TRANSACTION_DELETED,
    payload: null,
  }),
  loadProvince: () => ({
    type: actions.LOAD_PROVINCE,
  }),
  loadProvinceShipping: (payload) => ({
    type: actions.LOAD_PROVINCE_S,
    payload,
  }),
  loadCity: (payload) => ({
    type: actions.LOAD_CITY,
    payload: payload,
  }),
  loadCityShipping: (payload) => ({
    type: actions.LOAD_CITY_S,
    payload: payload,
  }),
  loadBrgy: (payload) => ({
    type: actions.LOAD_BRGY,
    payload: payload,
  }),
  loadBrgyShipping: (payload) => ({
    type: actions.LOAD_BRGY_S,
    payload: payload,
  }),
  loadTempTransaction: (payload) => ({
    type: actions.LOAD_TEMP_TRANSACTION,
    payload: payload,
  }),
  loadAllowedPchannels: (payload) => ({
    type: actions.LOAD_ALLOWED_PCHANNEL,
    payload: payload,
  }),
  resetAllowedPchannels: () => ({
    type: actions.RESET_ALLOWED_PCHANNEL,
  }),
  resetProvice: () => ({
    type: actions.RESET_PROVINCE,
  }),
  resetCity: () => ({
    type: actions.RESET_CITY,
  }),
  resetBrgy: () => ({
    type: actions.RESET_BRGY,
  }),
  resetBrgyCityShipping: () => ({
    type: actions.RESET_CITY_BRGY,
  }),
  submitTempTrx: (payload) => ({
    type: actions.SUBMIT_TEMP_TRX,
    payload: payload,
  }),
  redirectCustomerSubmitTrx: (payload) => ({
    type: actions.SUBMIT_TRX_REDIRECT_CUSTOMER,
    payload: payload,
  }),
  custRedirectPchannelData: (payload) => ({
    type: actions.REDIRECT_CUSTOMER_PCHANNEL_DATA,
    payload: payload,
  }),
  submitTempTrxReset: () => ({
    type: actions.SUBMIT_TEMP_TRX_RESET,
  }),
  tagFolderMessageReset: () => ({
    type: actions.TAG_FOLDER_MESSAGE,
    payload: null,
  }),
  removeTransactionTag: (payload, tag_id) => ({
    type: actions.REMOVE_TXN_TAG,
    payload: payload,
    tag_id: tag_id,
  }),
  submitApplication: (payload) => ({
    type: actions.SUBMIT_APPLICATION,
    payload: payload,
  }),
  validateApplication: (payload) => ({
    type: actions.VALIDATE_APPLICATION,
    payload: payload,
  }),
  resetValidation: () => ({
    type: actions.RESET_VALIDATION,
  }),
  resetApplication: () => ({
    type: actions.RESET_APPLICATION,
  }),
  setBizModule: (payload) => ({
    type: actions.SET_BIZ_MODULE,
    payload,
  }),
  getLimit: () => ({
    type: actions.GET_THRESHOLD,
  }),
  countStoreInventory: (payload) => ({
    type: actions.COUNT_STORE_INVENTORY,
    payload,
  }),
  resetTempErrorMessage: () => ({
    type: actions.TEMP_ERROR_MESSAGE,
    payload: null,
  }),
  submitTempTransaction: (payload) => ({
    type: actions.SUBMIT_TEMP_TRANSACTION,
    payload,
  }),
  resetTempTransactionMessage: () => ({
    type: actions.RESET_TEMP_TRANSACTION_MESSAGE,
  }),
  fetchTrxStatus: (payload) => ({
    type: actions.FETCH_TRX_STATUS,
    payload: payload,
  }),
  searchAuditLogs: (payload) => ({
    type: actions.SEARCH_AUDITLOGS,
    payload: payload,
  }),
  searchMerchantAuditLogs: (payload) => ({
    type: actions.SEARCH_MERCHANT_AUDITLOGS,
    payload: payload,
  }),
  loadMoreLogs: (payload) => ({
    type: actions.LOAD_MORE_LOGS,
    payload: payload,
  }),
  loadMoreMerchantLogs: (payload) => ({
    type: actions.LOAD_MORE_MERCHANT_LOGS,
    payload: payload,
  }),
  downloadTrxPdf: (payload) => ({
    type: actions.DOWNLOAD_TRANSACTION_PDF,
    payload: payload,
  }),
  resetDownloadTrxPdf: () => ({
    type: actions.RESET_DOWNLOAD_TRANSACTION_PDF,
  }),
  resetAuditLogs: () => ({
    type: actions.RESET_AUDITLOGS,
  }),
  resetMerchantAuditLogs: () => ({
    type: actions.RESET_MERCHANT_AUDITLOGS,
  }),
  fetchPaymentFees: () => ({
    type: actions.FETCH_PAYMENT_FEES,
  }),
  getTransactionFees: (payload) => ({
    type: actions.FETCH_TRANSACTION_FEE,
    payload,
  }),
  resetRedirectCustError: () => ({
    type: actions.RESET_TRX_REDIRECT_CUSTOMER_ERROR,
  }),
  sendSmsLink: (payload) => ({
    type: actions.SEND_SMS_LINK,
    payload,
  }),
  resetSmsLink: () => ({
    type: actions.RESET_SEND_SMS_LINK,
  }),
  fetchBocTrxData: (payload) => ({
    type: actions.FETCH_BOC_TRANSACTION_DATA,
    payload,
  }),
  fetchDetailedTrxData: (payload) => ({
    type: actions.FETCH_DETAILED_TRANSACTION_DATA,
    payload,
  }),
  fetchTransaction: (payload) => ({
    type: actions.FETCH_TRANSACTION_PAYMENT,
    payload,
  }),
  getShippingSetup: (payload) => ({
    type: actions.FETCH_SHIPPING_SETUP,
    payload,
  }),
  checkCustomFeeCalculator: (payload) => ({
    type: actions.FETCH_CUSTOM_FEE_CALCULATOR,
    payload,
  }),
  getItemBody: (payload) => ({
    type: actions.FETCH_ITEM_BODY,
    payload,
  }),
  fetchBookingRequest: (payload) => ({
    type: actions.FETCH_BOOKING_REQUEST,
    payload,
  }),
  resetBookingRequest: () => ({
    type: actions.RESET_BOOKING_REQUEST,
  }),
  getCourierFee: (payload) => ({
    type: actions.COURIER_FEE,
    payload,
  }),
  fetchMerchantStoreSettingsTrx: (payload) => ({
    type: actions.FETCH_MERCHANT_STORE_SETTINGS_TRANX,
    payload,
  }),
  fetchStoreSettings: (payload) => ({
    type: actions.FETCH_STORE_SETTINGS,
    payload,
  }),
  resetBookingMessage: () => ({
    type: actions.RESET_BOOKING_MESSAGE,
  }),
  fetchCourierOrder: (payload) => ({
    type: actions.FETCH_COURIER_ORDER,
    payload: payload,
  }),
  fetchBookingStatus: () => ({
    type: actions.FETCH_BOOKING_STATUS,
  }),

  fetchShippingTimeline: (payload) => ({
    type: actions.FETCH_SHIPPING_TIMELINE,
    payload: payload,
  }),
  resetTimelineMqtt: () => ({
    type: actions.RESET_SHIPPING_TIMELINE_MQTT,
  }),
  reloadTimeline: (payload) => ({
    type: actions.RELOAD_SHIPPING_TIMELINE,
    payload
  }),
  resetTimeline: () => ({
    type: actions.RESET_SHIPPING_TIMELINE,
  }),
  fetchCustomerDataByIpAddress: () => ({
    type: actions.FETCH_CUSTOMER_DATA_BY_IP_ADDRESS,
  }),
  deleteCustomerDetailsInPhoneBook: (payload) => ({
    type: actions.DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK,
    payload,
  }),
  resetDeleteCustomerDetailsInPhoneBook: () => ({
    type: actions.DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_RESET,
  }),
  checkIfBirRulingComplied: (payload) => ({
    type: actions.CHECK_IF_BIR_RULING_COMPLIED,
    payload,
  }),
  checkIfBirRulingCompliedReset: () => ({
    type: actions.CHECK_IF_BIR_RULING_COMPLIED_RESET,
  }),
  fetchSupplierInformation: (payload, mid) => ({
    type: actions.FETCH_SUPPLIER_INFO,
    payload,
  }),
  resetFetchSupplierInfo: () => ({
    type: actions.FETCH_SUPPLIER_INFO_RESET,
  }),
  saveSupplierInfo: (payload) => ({
    type: actions.SAVE_SUPPLIER_INFO,
    payload,
  }),
  resetSaveSupplierInfo: (payload) => ({
    type: actions.SAVE_SUPPLIER_INFO_RESET,
    payload,
  }),
  updateSupplierInfo: (payload, supplierId) => ({
    type: actions.UPDATE_SUPPLIER_INFO,
    payload,
    supplierId: supplierId,
  }),
  resetUpdateSupplierInfo: () => ({
    type: actions.UPDATE_SUPPLIER_INFO_RESET,
  }),
  setNewSupplierFormValues: (payload) => ({
    type: actions.ADD_SUPPLIER_FORM_VALUES,
    payload,
  }),
  setFilteredSuppliersArray: (payload) => ({
    type: actions.SET_FILTERED_SUPPLIERS_ARRAY,
    payload,
  }),
  //CWT SETTINGS REDUX
  fetchCwtSettings: (payload) => ({
    type: actions.FETCH_CWT_SETTINGS,
    payload: payload,
  }),
  updateCwtSettings: (payload) => ({
    type: actions.UPDATE_CWT_SETTINGS,
    payload: payload,
  }),
  fetchCwtSettingsReset: () => ({
    type: actions.FETCH_CWT_SETTINGS_RESET,
  }),
  updateCwtSettingsReset: () => ({
    type: actions.UPDATE_CWT_SETTINGS_RESET,
  }),
  setCreateDisbursementsStepsState: (payload) => ({
    type: actions.CREATE_DISBURSEMENTS_STEPS_STATE,
    payload,
  }),
  // DISBURSEMENT LOGS
  fetchDisbursementLogs: (payload) => ({
    type: actions.FETCH_DISBURSEMENT_LOGS,
    payload,
  }),
  fetchCosmosOtpSettings: () => ({
    type: actions.FETCH_COSMOS_OTP_SETTINGS,
  }),
  fetchCosmosOtpSettingsReset: () => ({
    type: actions.FETCH_COSMOS_OTP_SETTINGS_RESET,
  }),
  requestOtpDisbursements: (payload) => ({
    type: actions.REQUEST_OTP_DISBURSEMENTS,
    payload
  }),
  requestOtpDisbursementsReset: () => ({
    type: actions.REQUEST_OTP_DISBURSEMENTS_RESET,
  }),
  validateOtpDisbursements: (payload) => ({
    type: actions.VALIDATE_OTP_DISBURSEMENTS,
    payload
  }),
  validateOtpDisbursementsReset: () => ({
    type: actions.VALIDATE_OTP_DISBURSEMENTS_RESET,
  }),
  createSupplierDisbursement: (payload) => ({
    type: actions.CREATE_SUPPLIER_DISBURSEMENT,
    payload
  }),
  createSupplierDisbursementReset: () => ({
    type: actions.CREATE_SUPPLIER_DISBURSEMENT_RESET,
  }),
  fetchSupplierDisbursements: (pageNumber, searchWord) => ({
    type: actions.FETCH_SUPPLIER_DISBURSEMENTS,
    pageNumber,
    searchWord,
  }),
  fetchSupplierDisbursementsReset: () => ({
    type: actions.FETCH_SUPPLIER_DISBURSEMENTS_RESET,
  }),
  setSupplierDisbursements: (payload) => ({
    type: actions.SET_SUPPLIER_DISBURSEMENTS,
    payload
  }),
};
export default actions; 